<template>
  <div class="box4">404</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.box4{
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 40px;
}
</style>